import React, { Component } from 'react';
import './information-product.scss';
import Header from '../common/header';
import 'sweetalert2/src/sweetalert2.scss';
import 'react-datepicker/dist/react-datepicker.css';
import * as Consts from '../common/constants/const';
import Session from '../../sandbox/session';
import moment from 'moment';
import { formatCop } from '../../rsc/utils';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { GetBag } from '../../reducers';
import { parse, stringify } from 'flatted';
import Slider from 'react-slick';  // Importar react-slick

class InformationProduct extends Component {
  state = {
    currentImageIndex: 0,
  };

  componentDidMount() {
    const { product, GetBag } = this.props;
    GetBag(product);
  }

  handleBuyProduct = () => {
    const { bag } = this.props;
    const itemsShop = parse(localStorage.getItem('itemsshop')) || [];
    localStorage.setItem('itemsshop', stringify([...itemsShop, bag.results[0]]));
    window.location.hash = '#/tienda';
  };

  renderComments(comments) {
    if (!comments.length) {
      return <span className="c-f ta-c">Sin comentarios, para agregar un comentario debes comprar este producto</span>;
    }

    return comments.map((comment) => (
      <div className="media-inf-view-prd" key={comment.date}>
        <div className="media-body mg-l-15">
          <strong className="c-b">{comment.name}&nbsp;</strong>
          <span>{comment.message}</span>
          <h6 className="mg-0 ta-e">{moment(comment.date).format('MMM DD hh:mm a')}</h6>
        </div>
      </div>
    ));
  }

  renderCalifications(califications) {
    if (!califications.length) {
      return <span className="c-f ta-c">Sin Calificaciones, para calificar debes comprar este producto</span>;
    }

    return califications.map((calification) => {
      const countStars = Math.ceil(
        (parseInt(calification.question_qualify) +
          parseInt(calification.question_punctuality) +
          parseInt(calification.question_expected) +
          parseInt(calification.question_price) +
          parseInt(calification.question_design)) / 5
      );

      const stars = Array.from({ length: 5 }, (_, i) => (
        <span key={i} className={`estrella${i < countStars ? ' llena' : ''}`}></span>
      ));

      return (
        <div className="media-inf" key={calification.name}>
          <div className="media-body mg-l-15 content-califications">
            <strong className="c-b">{calification.name}</strong>
            <div className="ct-cal-view0">{stars}</div>
            {Session.instance.questions && (
              <div className="ct-cal-view1">
                {Object.entries(Session.instance.questions).map(([key, question]) => (
                  <span key={key} className="tx-it-q fz-xs d-f jc-sb">
                    {question}
                    <span>
                      <span className="estrella llena"></span>
                      {calification[key]}
                    </span>
                  </span>
                ))}
              </div>
            )}
            <h6 className="mg-0 ta-e c-b">{moment(calification.date).format('MMM DD hh:mm a')}</h6>
          </div>
        </div>
      );
    });
  }

  renderProducts(products) {
    if (!products) return null;

    return products.map((product) => (
      <span className="h-2-5" key={product.title} ref={product.title}>
        ∙{product.title}
      </span>
    ));
  }

  render() {
    const { bag } = this.props;
    const product = bag.results?.[0] || {};
    const { quantity, images = [], comments = [], califications = [], products = [], price, sales } = product;
    const countStarsBreakfast = Math.ceil(
      califications.reduce((acc, cal) => {
        const totalStars = parseInt(cal.question_qualify) +
          parseInt(cal.question_punctuality) +
          parseInt(cal.question_expected) +
          parseInt(cal.question_price) +
          parseInt(cal.question_design);
        return acc + Math.ceil(totalStars / 5);
      }, 0) / califications.length
    ) || 5;

    // Configuración del carrusel
    const carouselSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="body-content-view">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.product} | ArtesaniasGb</title>
        </Helmet>
        <Header header={<h2 className="title-page">{this.props.product} | ArtesaniasGb</h2>} store={false} />
        <article className="content-view">
          <section className="sect-carrousel">
            <div className="it-sec sect-image">
              {quantity < 1 && (
                <div className="content-view-products content-sold-out d-f jc-c ai-c cp-n">
                  <span> SOLD OUT</span>
                </div>
              )}
            <Slider {...carouselSettings}>
              {images.map((media, index) => {
                if (media.type === "video") {
                  return (
                    <div key={index}>
                      <video controls>
                        <source src={media.drive_key} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  );
                } 
                if (media.type === "image") {
                  return (
                    <div key={index}>
                      <img src={media.drive_key} alt={`Media ${index + 1}`} />
                    </div>
                  );
                }
                return null; // Fallback por si no es ni video ni imagen
              })}
            </Slider>

            </div>
          </section>
         
          <section className="sect-inf-prod">
            <div className="it-sec d-f fd-c jc-sa ai-c">
              <div className="d-f fd-r ct-sub3">
                <h3 className="c-t">
                  Precio: {formatCop(price)} <small>{Consts.txCurrency}</small>
                </h3>
                <span className="ct-cal">
                  +{countStarsBreakfast}
                  <span className="estrella llena"></span>
                </span>
              </div>
              <div className="d-f fd-r">
                <span>Contenido: </span>
                {this.renderProducts(products)}
              </div>
            </div>
            <div className="it-sec d-f ai-c jc-sa">
              <h3 className="vendidos">
                {sales} {Consts.txSold}
              </h3>
              <div onClick={this.handleBuyProduct}>
                <span className="button-continue">{Consts.txBuy}</span>
              </div>
            </div>
          </section>

          <section className="sect-inf-prod2">
            <div className="it-sec d-f fd-r">
              <div className="subit-sec">
                <h3>Comentarios</h3>
                {this.renderComments(comments)}
              </div>
              <div className="subit-sec">
                <h3>Calificaciones</h3>
                {this.renderCalifications(califications)}
              </div>
            </div>
          </section>

        </article>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bag: state.setInfoData.bag,
});

const mapDispatchToProps = {
  GetBag,
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationProduct);
